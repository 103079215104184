import React, { useCallback, useMemo } from 'react';

import { UseQueryResult } from 'react-query';
import { useRecoilValue } from 'recoil';

import { useGetBusinesses } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';
import { IReport, ITemplate } from '@src/types/report_service/report';

import Filter from '@src/components/ui_v2/filter';
import { RadioInput } from '@src/components/ui_v2/inputs';

import BusinessCustomReports from './custom_reports';
import { reportDataPeriodType } from '../atoms';
import BusinessReportsMultiAddItemField from '../common/business_multi_add_item_field';
import BusinessReportsMultiSelectField from '../common/business_multi_select_field';

interface IBusinessReportsExportContentProps {
  business: IBusiness,
  category: string,
  type: string,
  slug: string,
  reports: IReport[],
  query: UseQueryResult<IReport[], Error>,
  include: boolean,
  exclude: boolean,
  templates: ITemplate[],
  setInclude: React.Dispatch<React.SetStateAction<boolean>>,
  setExclude: React.Dispatch<React.SetStateAction<boolean>>,
}

const BusinessReportsExportContent = ({
  business,
  category,
  type,
  slug,
  reports,
  query,
  include,
  exclude,
  templates,
  setInclude,
  setExclude,
}: IBusinessReportsExportContentProps) => {
  const periodType = useRecoilValue(reportDataPeriodType);
  const businessQuery = useGetBusinesses({
    includeEmployedBusiness: true,
    simple:                  true,
  });
  const businesses = useMemo(() => {
    const businessesPages = businessQuery.data?.collection || [];
    return businessesPages;
  }, [businessQuery.data?.collection]);

  const report = useMemo(() => (
    reports.find((rep: IReport) => rep.slug === slug)
  ), [reports, slug]);

  const handleInclude = useCallback((checked: boolean) => {
    setInclude(checked);
    setExclude(false);
  }, [setInclude, setExclude]);

  const handleExclude = useCallback((checked: boolean) => {
    setInclude(false);
    setExclude(checked);
  }, [setExclude, setInclude]);

  return (
    <div className="reports-export-content">
      <div className="content-header">
        <div>
          <h3 className="title">Reports Details</h3>
          <p className="subtitle">
            Select business, reports, accounting period and specify whether to include drill down.
          </p>
        </div>
        <a className="pointer text-color" role="button" tabIndex={ -1 }>
          Autofill Saved Details
        </a>
      </div>
      <div className="content-select">
        <div className="select-field-item">
          <div className="select-label">
            <p className="select-label-txt">Select Business Report(s):</p>
          </div>
          <BusinessReportsMultiSelectField
            category={ category }
            className="field"
            reports={ reports }
            slug={ slug }
            templates={ templates }
            type={ type }
          />
        </div>
        <div className="select-field-item">
          <div className="select-label">
            <p className="select-label-txt">Select Business(es:)</p>
          </div>
          <BusinessReportsMultiAddItemField businessId={ business.id } businesses={ businesses } className="field" />
        </div>
        <div className="select-field-item">
          <div className="select-label">
            <p className="select-label-txt">Select Accounting Period:</p>
          </div>
          { type === 'consolidated_report' && (<Filter.MonthField className="field" name="reportDataExport" />) }

          { type === 'report' && (<Filter.DateField className="field" name="reportDataExport" periodType={ periodType } />) }
        </div>

        {report && !report.editMappingDisabled && (
          <div className="select-field-item">
            <div className="select-label" style={ { marginTop: 0 } }>
              <p className="select-label-txt">Drill down:</p>
            </div>
            <div className="check-content">
              <RadioInput
                small
                checked={ include }
                hiddenTitle="Include"
                title="Include"
                onChange={ handleInclude }
              />
              <RadioInput
                small
                checked={ exclude }
                hiddenTitle="Exclude"
                title="Exclude"
                onChange={ handleExclude }
              />
            </div>
          </div>
        )}
      </div>
      <div className="content-header">
        <div>
          <h3 className="title">Customize Reports</h3>
          <p className="subtitle">
            Select the report columns below to be included in the export:
          </p>
        </div>
      </div>
      <BusinessCustomReports
        category={ category }
        query={ query }
        reports={ reports }
        slug={ slug }
        templates={ templates }
        type={ type }
      />
    </div>
  );
};

export default BusinessReportsExportContent;
